import React from "react"

import { graphql, PageProps } from "gatsby"
import Layout from "../components/Layouts/mainPage"

import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/PkwHalva"
import { WhatAreTheBenefits } from "../components/WhatAreTheBenefits"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { CashbackHalva } from "../components/CashbackHalva"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefits } from "../components/MoreBenefits"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"
import { QUESTIONS_FOR_PKW } from "../components/Questions/helpers"

const bannerTitle = (
  <>
    До 10 месяцев <br /> рассрочки
  </>
)

const bannerSubTitle = (
  <>
    — Кэшбэк до 10%
    <br />
    — 17% на остаток по карте
    <br />— Обслуживание 0 ₽
  </>
)

const newWhatsAreTheBenefitsTetx =
  "Специальные предложения позволяют оформить рассрочку на 10 месяцев без переплат"

const whatsAreTheBenefitsPrice = "10 747 ₽ / мес."

const whatsAreTheBenefitsTitle = "10 месяцев рассрочки"

const subtextCalculator =
  "Вы не платите проценты, за вас это сделает магазин. Платеж делится на равные части каждый месяц. К тому же, по подписке «Халва.Десятка», срок рассрочки увеличивается до 10 месяцев у каждого партнера!"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, title, description, noIndex } = getPageData(data)

  return (
    <Layout noHeader noFooter title={title} description={description} noIndex={noIndex}>
      <NewHeader />
      <Banner title={bannerTitle} subTitle={bannerSubTitle} orderNum="1" />
      <CardWithMaxBenefits orderNum="2" />
      <FormPKW dataLayerName="shortPersonalForm" orderNum="3" />
      <HowInstallmentWorks orderNum="4" />
      <NewCalculator titleLocationBottom subtext={subtextCalculator} orderNum="4" />
      <WhatAreTheBenefits
        title={whatsAreTheBenefitsTitle}
        text={newWhatsAreTheBenefitsTetx}
        price={whatsAreTheBenefitsPrice}
        orderNum="5"
      />
      <CashbackHalva orderNum="6" />
      <MoreBenefits installmentMonth={10} orderNum="7" />
      <Partners orderNum="8" />
      <Reviews orderNum="9" />
      <Questions questionList={QUESTIONS_FOR_PKW} orderNum="10" />
      <NewFooter ligal={ligal} orderNum="11" />
    </Layout>
  )
}
export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/pkw-halva/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
    pageDataJson(pageName: { eq: "pkw-halva" }) {
      title
      description
    }
  }
`
