import React, { ReactFragment, useEffect, useReducer } from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"

import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Оформить карту"

const IDS = ["app", "calculator", "reviewCard"]

const OPTIONS = {
  rootMargin: "0px 0px 0px 0px",
  threshold: 0.25,
}

type ObserverType = { app: boolean; calculator: boolean; review: boolean }

function observerReducer(state: ObserverType, action: any) {
  if (IDS.includes(action.id)) {
    return { ...state, [action.id]: action.value }
  }

  throw new Error()
}

const initialObserverState: ObserverType = {
  app: false,
  calculator: false,
  review: false,
}

type BannerProps = {
  title?: ReactFragment
  subTitle?: ReactFragment
  orderNum?: string
}

export function Banner({ title, subTitle, orderNum }: BannerProps) {
  const [observerState, observerDispatch] = useReducer(observerReducer, initialObserverState)

  // Костыль. Не прокидывается массив со всеми id. Из-за этого кнопка не пропадает. Reducer хранит isIntersecting для каждого элемента по отдельности.
  const callback = (entries: IntersectionObserverEntry[]) => {
    for (let index = 0; index < entries.length; index += 1) {
      const element = entries[index]
      const { id } = element.target
      observerDispatch({ id, value: element.isIntersecting })
    }
  }

  useEffect(() => {
    const isSupported =
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    let observer: IntersectionObserver

    if (isSupported) {
      observer = new IntersectionObserver(callback, OPTIONS)

      IDS.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          observer.observe(element)
        }
      })
    }

    return () => {
      if (isSupported) {
        IDS.forEach((id) => {
          const element = document.getElementById(id)
          if (element) {
            observer.unobserve(element)
          }
        })
      }
    }
  }, [])

  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container className={styles.root}>
        <Container className={styles.container}>
          <Img alt="bg image" className={styles.img} />
          <div className={styles.textBlock}>
            <h1>{title}</h1>
            <div>{subTitle}</div>
          </div>
          <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
            {buttonText}
          </Button>
        </Container>
      </Container>
      <div
        className={clsx(
          styles.btnBlock,
          IDS.some((id) => observerState[id as keyof ObserverType]) && styles.hideMobBlock
        )}
      >
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </section>
  )
}
